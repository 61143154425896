import React from 'react';
import PropTypes from 'prop-types';
import regMap from '../assets/img/MAP-Regional-Bahai-Council-Boundaries.jpeg';
import { InputLabel, FormControl, Select, MenuItem } from '@material-ui/core';
import { Link } from 'react-router-dom';
import withContext from '../ContextAPI/Context_HOC';
import ErrorMessageToUser from '../Error/ErrorMessageToUser';

class RegionalCouncils extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      regionName: '',
      region: [],
      allRegions: [],
      errorOccurred: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.subscription = props.context.regionalCouncilService.getRegionalCouncils().subscribe({
      next: (x) => {
        this.setState({ allRegions: x });
      },
      error: () => this.setState({ errorOccurred: true }),
    });
  }
  handleChange = (event) => {
    const regionName = event.target.value;
    const selectedRegion = this.state.allRegions.filter((regionItem) => regionItem.regionName === regionName);
    this.setState({
      regionName: event.target.value,
      region: selectedRegion,
    });
  };
  componentWillUnmount() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
  render() {
    return (
      <div data-cy="displaydirectory">
        <h1>Regional Councils</h1>
        <Link to="/guidance/national_spiritual_assembly/results-of-balloting-for-regional-baha-i-councils-for-the-year-2023-24-2023/results-of-balloting-for-regional-baha-i-councils-for-the-year-2023-24-2023">
          View the Results of Balloting for Regional Bahá’í Councils for the Year 2023-24
        </Link>
        <br />
        {this.state.errorOccurred ? (
          <ErrorMessageToUser />
        ) : (
          <form id="select-form" autoComplete="off">
            <FormControl variant="outlined" margin="dense" className="medium-dropdown">
              <InputLabel htmlFor="region-simple" id="region">
                Region
              </InputLabel>
              <Select
                value={this.state.regionName}
                onChange={this.handleChange}
                labelId="region"
                label="Region"
                data-cy="regionSelect"
                inputProps={{
                  name: 'region',
                  id: 'region-simple',
                }}
                className={'highlight-on-focus'}
              >
                {this.state.allRegions.length ? (
                  this.state.allRegions
                    .sort((a, b) => {
                      if (a.regionName < b.regionName) {
                        return -1;
                      }
                      if (a.regionName > b.regionName) {
                        return 1;
                      }
                      return 0;
                    })
                    .map((regionItem, index) => (
                      <MenuItem data-cy={`region${regionItem.regionName}`} key={index} value={regionItem.regionName}>
                        {regionItem.regionName}
                      </MenuItem>
                    ))
                ) : (
                  <MenuItem data-cy={`regionLoading`} key={'regionLoading'} value="" disabled={true}>
                    Loading...
                  </MenuItem>
                )}
              </Select>
            </FormControl>
          </form>
        )}
        {this.state.region.map((regionItem, index) => (
          <div key={index}>
            <h3>{regionItem.name}</h3>
            <ul className="plain-list">
              <li data-cy="states">
                <strong>States: </strong>
                {regionItem.stateList.join(', ')}
              </li>
              <li data-cy="name">
                <strong>Contact Name: </strong>
                {regionItem.contact.title ? regionItem.contact.title : null}
                {regionItem.contact.firstName ? ' ' + regionItem.contact.firstName : null}
                {regionItem.contact.nickName ? ' "' + regionItem.contact.nickName + '"' : null}
                {regionItem.contact.middleName ? ' ' + regionItem.contact.middleName : null}
                {regionItem.contact.lastName ? ' ' + regionItem.contact.lastName : null}
                {regionItem.contact.suffix ? ' ' + regionItem.contact.suffix : null}
              </li>
              {regionItem.formattedPhone != null && (
                <li data-cy="phone">
                  <strong>Phone: </strong>
                  <a href={'tel:' + regionItem.formattedPhone}>{regionItem.formattedPhone}</a>
                </li>
              )}
              <li data-cy="email">
                <strong>Email: </strong>
                <a href={'mailto:' + regionItem.contact.email}>{regionItem.contact.email}</a>
              </li>
              <li data-cy="address">
                <strong>Address: </strong>
                {regionItem.contact.line1}
                {regionItem.contact.line2 ? <span>, {regionItem.contact.line2}</span> : null}
                {regionItem.contact.line3 ? <span>, {regionItem.contact.line3}</span> : null}
                {regionItem.contact.line4 ? <span>, {regionItem.contact.line4}</span> : null}
                {regionItem.contact.line5 ? <span>, {regionItem.contact.line5}</span> : null}, {regionItem.contact.city},{' '}
                {regionItem.contact.state} {regionItem.contact.zipCode}
              </li>
              {regionItem.contact.website != null && (
                <li>
                  <strong>Website: </strong>
                  <a href={regionItem.contact.website}>{regionItem.contact.website}</a>
                </li>
              )}
            </ul>
          </div>
        ))}
        {this.state.regionName === 'California' ? (
          <Link to="/directories/councils/california"> California Regional Bahá’í Council </Link>
        ) : null}
        <hr />
        <p>
          Download:{' '}
          <a href="https://bahai-web.s3.us-east-1.amazonaws.com/RBC_elections/2024/Membership+of+Regional+Baha'i+Councils+2024-25.pdf">
            Membership of Regional Bahá’í Councils 2024-25
          </a>
        </p>
        <p>
          Download:{' '}
          <a href="https://bahai-web.s3.amazonaws.com/tab/regions/2023-24+Regional+Training+Institute+Boards+-+updated+November+2024.pdf">
            Regional Training Institute Boards - updated November 2024
          </a>
        </p>
        <p>
          Download:{' '}
          <a href="https://bahai-web.s3.amazonaws.com/tab/regions/2023-24+Directory+of+Regional+Bahai+Councils+of+the+United+States.pdf">
            Contact Information
          </a>
        </p>
        <img src={regMap} height="500px" alt="regional map" />
      </div>
    );
  }
}

RegionalCouncils.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withContext(RegionalCouncils);

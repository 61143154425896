import React from 'react';

function StoriesForTheAmericanBahai() {
  return (
    <div>
      <h1>
        Sending stories to <em>The American Bahá’í</em>
      </h1>
      <h3>
        Interested in submitting material for publication in <em>The American Bahá’í</em> ? Please read this page first!
      </h3>
      <ul className="plain-list">
        <li>
          <a href="#basic">Important basic points on submissions</a>
        </li>
        <li>
          <a href="#timely">News articles: Timely, relevant, interesting</a>
        </li>
        <li>
          <a href="#photos">Photos: Show your faces</a>
        </li>
        <li>
          <a href="#commentaries">Commentaries</a>
        </li>
        <li>
          <a href="#tributes">In memoriam/Tributes in brief</a>
        </li>
        <li>
          <a href="#calendar">Calendar and advertising </a>
        </li>
        <li>
          <a href="#wedont">Items we usually won’t publish</a>
        </li>
        <li>
          <a href="#persianspanish">Persian and Spanish sections</a>
        </li>
      </ul>
      <hr />
      <h3 id="basic" className="anchor-heading">
        Important basic points on submissions
      </h3>
      <p>
        <em>The American Bahá’í</em> is published every two months.
      </p>
      <br />
      <p>
        Please submit articles, letters and photos to: <em>The American Bahá’í</em> (e-mail{' '}
        <a href="mailto:tab@usbnc.org">tab@usbnc.org</a>).
      </p>
      <br />
      <p>
        Here are deadlines for the 2025 printed and digital editions of <em>The American Bahá’í</em> (deadlines are also published
        on the <strong>inside back cover</strong> of every issue several months in advance):
      </p>
      <ul>
        <li>
          <strong>January 3, 2025</strong> is the deadline for the March/April 2025 issue
        </li>
        <li>
          <strong>March 7, 2025</strong> is the deadline for the May/June 2025 issue
        </li>
        <li>
          <strong>May 5, 2025</strong> is the deadline for the July/August 2025 issue
        </li>
        <li>
          <strong>June 30, 2025</strong> is the deadline for the September/October 2025 issue
        </li>
        <li>
          <strong>September 5, 2025</strong> is the deadline for the November/December 2025 issue
        </li>
        <li>
          <strong>November 10, 2025</strong> is the deadline for the January/February 2026 issue
        </li>
      </ul>
      <p>Please consider the following:</p>
      <ul>
        <li>Share your email and phone number with your submission.</li>
        <li>We can’t always promise publication, even if your material meets the deadline.</li>
        <li>Limit your written submission to 800 words.</li>
        <li>Include names of those featured in photos with one, two or three people.</li>
        <li>We reserve the right to edit for content or length or to emphasize a particular Plan-centered theme.</li>
        <li>We promise our best effort to maintain the accuracy and stay true to the spirit of the submission.</li>
      </ul>
      <hr />
      <h3 id="timely" className="anchor-heading">
        News articles: Timely, relevant, interesting
      </h3>
      <br />
      <h5>Keep it timely</h5>
      <p>
        Please bear in mind that the editors consider a number of things in deciding whether to publish an article about local
        activities on the Web or in an upcoming magazine &#8211; and timeliness is high on that list. Time is needed to edit and
        review each article before it can be posted on the Web. And for each printed issue, additional time is needed to select
        and further edit articles, then to produce, print and mail each issue &#8211;sometimes up to two months.
      </p>
      <p>These automatic delays make it all the more important that you:</p>
      <ul>
        <li>
          Alert the editors in advance by e-mail, letter or phone if an event that would interest the Bahá’ís nationwide is coming
          up in the next six months. We can then mention it in the Calendar of Events in a timely manner.
        </li>
        <li>
          Send your reports on local events promptly, ideally within a week or two after the happening. In most cases, if you wait
          much longer the report can lose relevance quickly.
        </li>
        <li>
          Be conscious of the printed edition&#8217;s deadlines (published above and on page 2 of each print issue). Send material
          so that it arrives well beforehand, when possible.
        </li>
      </ul>
      <h5>Keep it relevant</h5>
      <p>
        When sending us information on your local efforts, please remember to state exactly how the endeavor fits in with the
        goals of the current Plan, if applicable. The National Spiritual Assembly publishes The American Bahá’í primarily to
        inspire the Bahá’ís to meet the current international and national Plans, particularly as detailed in each year’s Ridván
        message from the Universal House of Justice.
      </p>
      <p>
        This priority leaves plenty of room for news of how communities are inviting people to participate in devotional
        gatherings, study circles, children’s classes, junior youth groups and teaching activities; training its human resources
        to prepare for accelerated growth; involving youth, junior youth and children in leadership roles; using the arts in all
        activities; reaching out to teach the Faith; fostering a warm, loving community environment; getting involved in service
        to general society; or otherwise advancing the process of entry by troops.
      </p>
      <p>
        But often it will prevent us from publishing articles that might otherwise be interesting. A frequent example: If a
        prominent or “celebrity” Bahá’í visits your locality, before you report to us, focus on changes for the better that your
        community put into practice as a result. That’s what will be useful for Bahá’ís across the country.
      </p>
      <br />
      <h5>Capture and hold reader interest</h5>
      <p>Many of these points may seem obvious, but they bear stating here:</p>
      <ul>
        <li>
          <strong>If it makes the biggest difference, put it first.</strong> What results helped your community advance the Plan?
          What specific occurrences make your story come alive? What did your event or project do for the community, for the good
          of humanity, for people’s hearts? Try to make your first two paragraphs contain at least some element of this most
          important or interesting point. You can explain details later in the story.
        </li>
        <li>
          <strong>Who, what, when, where, why, how.</strong> At least the first four of these elements should be in the first
          paragraph of a straight, factual report. Still, the “why” or “how” often can be the most interesting part.
        </li>
        <li>
          <strong>Be economical with words.</strong> Rather than providing a complete list of activities or a session-by-session
          narrative, focus on this: What highlights would be inspiring or useful to you if you lived far away? Don’t be afraid to
          summarize, then summarize further. But: Don’t leave out places, dates or full names. We’d rather work with too much
          factual information of this type than too little.
        </li>
        <li>
          <strong>People like to hear from people.</strong> Once you’ve summarized the essentials, insert a few interesting direct
          quotes or actions demonstrating how people feel about the subject of your article.
        </li>
        <li>
          <strong>Let the praise flow from the facts or quotes.</strong> For example, if an activity contributes toward meeting
          national or international goals, say so. If a participant or observer feels joy or admiration, quote that person in his
          or her words.
        </li>
        <li>
          <strong>Please understand if we have to edit for length.</strong> Even your most conscientiously condensed report may
          not fit the space we have available. We promise our best efforts to maintain accuracy and stay true to the spirit of the
          article.
        </li>
      </ul>
      <p>
        Once again, here&#8217;s the e-mail address for submissions: <a href="mailto:tab@usbnc.org">tab@usbnc.org</a>
      </p>
      <hr />
      <h3 id="photos" className="anchor-heading">
        Photos: Show your faces
      </h3>
      <p>
        Photographs showing Bahá’ís and Bahá’í activity are an unequaled way for <em>The American Bahá’í</em> to reach straight to
        the hearts of its readers and foster a feeling of unity. Please keep these tips in mind:
      </p>
      <ul>
        <li>
          <strong>Show activity or emotion, clearly.</strong> The best photos often show a single activity or a singular emotion
          in a simple, relatively uncluttered scene. Try these tests: Does the eye naturally go toward a person or thing near the
          center of the photo, and do you want to keep looking at it? Does the photograph evoke an emotion or mood such as joy,
          excitement or empathy?
        </li>
        <li>
          <strong>Remember appropriateness.</strong> Photos of activities should be unrehearsed, i.e. not set up. But dignity (not
          stiffness) of posture, clothing and setting remain essential.
        </li>
        <li>
          <strong>Compose pictures with care.</strong> In taking the photo, try to get close to the subject; in fact, most good
          photos include only four people or fewer. But don’t cut out tops of heads or essential parts of the action. Sometimes
          it&#8217;s best to hold the camera vertically, rather than horizontally, to frame the best image up close. If you can,
          angle the shot so the background is light-colored.
        </li>
        <li>
          <strong>Stay sharp.</strong> Blurry images usually are unusable, so be aware of the focus and hold the camera steady.
        </li>
        <li>
          <strong>Try to take pictures in consistent light.</strong> Brightly lighted surroundings are best, but beware of
          splotchy shadows in the picture &#8211; they can be distracting and cause problems in printing.
        </li>
        <li>
          <strong>Let the faces show.</strong> Make sure the faces in the photos you choose are well-lighted and can be seen
          clearly against the background.
        </li>
        <li>
          <strong>Identify people and their activities.</strong> Please state clearly who is doing what in the photo, and where
          and when it took place. We don’t want to guess. But: Instead of writing caption information on the back of a photo, the
          best method is to type or write it on a separate sheet included in the mailing. If two or more photos are included,
          state clearly which caption belongs to each photo.
        </li>
        <li>
          <strong>Who took the picture?</strong> We like to give photo credits. Please let us know.
        </li>
        <li>
          <strong>High-quality prints.</strong> If you took the picture with a film camera, we can use color or black-and-white
          prints on photo paper. Please avoid sending Xeroxes, negatives or slides.
        </li>
        <li>
          <strong>Handle with care.</strong> Mail photos in a proper envelope or with relatively stiff cardboard included to
          prevent folding. Avoid using staples or paper clips!
        </li>
      </ul>
      <h5>Sending photos electronically</h5>
      <ul>
        <li>
          <strong>Pictures taken with a digital camera:</strong> Please take high-resolution or &#8220;fine&#8221; pictures, if
          your camera has that setting, and send us the image file as unchanged as possible, straight out of the camera.
          Unfortunately, many home digital cameras (especially mobile phones) still create small image files that look coarse on
          the printed page, even if they look good on a computer or TV screen. A rule of thumb: If your camera doesn’t have at
          least a 5-megapixel capacity, it probably won’t create good images for print, unless you’ve taken a very close-up
          portrait that will look good printed small.
        </li>
        <li>
          <strong>Scanning a photo taken with film:</strong> If you scan traditional photo prints, scan them full size at 300 dpi.
          As an alternative, photo CDs are available at many photo development stores, and those usually provide very good
          electronic images for our use. Slides are more complex; your best bet is to have them professionally scanned.
        </li>
        <li>
          <strong>Electronically enlarging (&#8220;rezzing up&#8221;) small image files:</strong> In a word &#8211; DON’T. Please,
          don’t. It won’t fool the eye. Everything in the picture will look jagged-edged once it&#8217;s in the paper.
        </li>
        <li>
          <strong>Format:</strong> Send us a JPEG/.jpg image at high quality (level 9 or higher in Photoshop) or a TIFF/.tif
          image. Other formats such as .bmp, .tga, .gif or .wmf translate poorly to the printed page. If e-mailing is the only
          feasible way to send them, please attach only one photo per e-mail message. Preferrably, ship us the photo(s) on CD or
          DVD. Electronic photo files suitable for our use are very large (usually 3 MB or larger) and sending them over e-mail
          can be cumbersome.
        </li>
        <li>
          <strong>Information about your images:</strong> Please read the sections above titled &#8220;Identify people and their
          activities&#8221; and &#8220;Who took the picture?&#8221;. Then include that information in a cover letter or
          accompanying e-mail. We truly appreciate your spending an extra few minutes with this.
        </li>
        <li>
          <strong>What NOT to send us:</strong> Please do not send us home print-outs of computer images. Also, avoid sending
          images that have been embedded into a Word document; those cause difficulties and are often impossible to use.
        </li>
      </ul>
      <hr />
      <h3 id="commentaries" className="anchor-heading">
        Commentaries
      </h3>
      <p>
        Commentaries may be accepted if well-written and relevant to the current Plan. We would aim for a maximum of 800 words;
        submissions may be edited for length and style.
      </p>
      <p>
        <em>The American Bahá’í</em> no longer maintains a &quot;Letters to the Editor&quot; column.{' '}
      </p>
      <hr />
      <h3 id="tributes" className="anchor-heading">
        In memoriam/Tributes in brief{' '}
      </h3>
      <p>
        To ensure that a departed Bahá’í is listed in the In Memoriam box, the Membership Office must be informed. A Spiritual
        Assembly, registered group or family member may inform the Membership Office, 1233 Central St., Evanston, IL 60201 (phone{' '}
        <a href="tel:847–869–9039">847–869–9039</a>, email <a href="mailto:membership@usbnc.org">membership@usbnc.org</a>). Please
        include all the following information on the deceased, if available:{' '}
      </p>
      <ul>
        <li>Full name</li>
        <li>Bahá’í ID number</li>
        <li>Date of passing</li>
        <li>Last known address</li>
      </ul>
      <p>
        If a recently passed Bahá’í has been outstanding in Bahá’í or other service, The American Bahá’í will be interested in
        publishing an obituary article in its online edition. Please send this report directly to The American Bahá’í and include
        information on the person’s life, services and accomplishments. We usually want to know such details as the honored
        person’s age, date and place of birth, dates and places of significant service, whether the person was born into a Bahá’í
        family or converted to the Faith (and if so, when and where), and other tidbits that reveal the person’s character and
        personality as appropriate.
      </p>
      <p>
        <a href="mailto:tab@usbnc.org">Submit</a> information for full obituary articles
      </p>
      <hr />
      <h3 id="calendar" className="anchor-heading">
        Calendar and advertising
      </h3>
      <br />
      <h5>Calendar</h5>
      <p>
        The Calendar pages list future happenings sponsored by national, regional or local Bahá’í institutions. Please bear in
        mind that a Calendar listing amounts to a general invitation for Bahá’ís from across the nation to participate, so be
        prepared for nationwide response. Please tell us which institution is sponsoring the event and whether the gathering is
        targeted to a particular audience (for example youth, seniors, those who have completed Ruhi Book 1, etc.)
      </p>
      <p>
        <a href="mailto:tab@usbnc.org">Send</a> Calendar of Events items
      </p>
      <br />
      <h5>Classified advertising</h5>
      <p>
        Classified notices in <em>The American Bahá’í</em> are published free of charge to the Bahá’í community. Because of this,
        notices are limited to items relating to the Faith; no personal or commercial ads are accepted. Moreover:
      </p>
      <ul>
        <li>We publish “Help Wanted” ads only when the employer is a Spiritual Assembly or other Bahá’í institution.</li>
        <li>
          We publish ads seeking volunteers or traveling teachers only when a Spiritual Assembly or other Bahá’í institution is
          fully responsible for the volunteer project.
        </li>
        <li>
          We publish ads seeking attendees for “reunion” events only when a Spiritual Assembly or other Bahá’í institution
          sponsors the event.
        </li>
        <li>Individual Bahá’ís may submit ads seeking help for a research project that will support a published work.</li>
      </ul>
      <p>
        <a href="mailto:tab@usbnc.org">Send</a> classified ads
      </p>
      <br />
      <h5>Homefront pioneering</h5>
      <p>
        Appeals for homefront pioneers may be published in the Classified section. Priority will be given to goal communities
        designated by the Regional Bahá’í Councils. Because the Regional Councils coordinate homefront pioneering,{' '}
        <strong>local communities should notify the appropriate Regional Council</strong> when they submit appeals for homefront
        pioneers to <em>The American Bahá’í</em>. These items should include brief, general descriptions of the local community’s
        Bahá’í administrative and teaching needs, common employment fields, educational and cultural opportunities and other local
        amenities and attractions.
      </p>
      <p>
        We <strong>do not list</strong> specific job openings unless the employer is a Bahá’í institution.
      </p>
      <p>
        Send homefront pioneering appeals to: <a href="mailto:tab@usbnc.org">tab@usbnc.org</a> and please also notify the Regional
        Bahá&#8217;í Council for your region.
      </p>
      <br />
      <h5>Display advertising</h5>
      <p>
        Because <em>The American Bahá’í</em> does not charge for advertising, display notices are limited to:
      </p>
      <ul>
        <li>
          Items advertised for sale by the Bahá’í Distribution Service, or by any other agency of the Universal House of Justice
          or National Spiritual Assembly.
        </li>
        <li>
          Events sponsored by the Universal House of Justice, the National Spiritual Assembly, a Regional Bahá’í Council or their
          agencies or affiliated organizations.
        </li>
      </ul>
      <p>
        We do not publish display advertising for locally sponsored events, for private businesses or for anything not directly
        connected with a Bahá’í administrative institution.
      </p>
      <hr />
      <h3 id="wedont" className="anchor-heading">
        Items we usually won&#8217;t publish
      </h3>
      <p>
        To utilize the limited resources and space of The American Bahá&#8217;í fairly and manageably, here are some items we will
        most often turn down:
      </p>
      <ul>
        <li>
          <strong>Lengthy commentaries</strong>, unless filled with new, relevant information.
        </li>
        <li>
          <strong>Articles not directly addressing goals</strong> of the global and national plans. The magazine no longer
          publishes “Excellence in All Things” or full-length obituaries. Substantial articles about Bahá’ís receiving recognition
          for service to larger society may be published on a case-by-case basis, if the recognition is on a national scale.
        </li>
        <li>
          <strong>Advance items promoting local activities,</strong> except those sponsored by the National Spiritual Assembly,
          its agencies, or Regional Bahá’í Councils. This includes articles, display ads or classifieds. However, if a locally
          sponsored activity is truly geared to invite Bahá’ís from the whole nation, or at least from an entire region, it can be
          listed in advance in the Calendar pages.
        </li>
        <li>
          <strong>Items or services for sale,</strong> except for those offered through the Bahá’í Distribution Service or other
          agency of the National Spiritual Assembly.
        </li>
        <li>
          <strong>Classified ads regarding specific job openings</strong> in cases where a Bahá’í institution is not the employer.
        </li>
        <li>
          <strong>Classified ads submitted by individuals,</strong> except for those seeking information for book research.
        </li>
        <li>
          <strong>Poetry or fiction.</strong> We simply don’t have the expertise or time to evaluate purely literary work, and it
          is outside our central mission.
        </li>
      </ul>
      <hr />
      <h3 id="persianspanish" className="anchor-heading">
        Persian and Spanish sections
      </h3>
      <br />
      <h5>Persian section</h5>
      <p>
        The Persian-language pages of <em>The American Bahá’í</em> are included as a service to the Persian-speaking friends
        within the United States.
      </p>
      <p>
        They include major messages of the Universal House of Justice and of the National Spiritual Assembly, summary translations
        of selected English-language reports from <em>The American Bahá’í</em>, and notices of specific interest to
        Iranian-Americans.
      </p>
      <p>
        Content for the Persian pages is contributed by the National Spiritual Assembly’s Office of Persian-American Affairs:{' '}
        <a href="mailto:persian@usbnc.org">persian@usbnc.org</a>
      </p>
      <br />
      <h5>Spanish section</h5>
      <p>
        The Spanish-language pages of <em>The American Bahá’í</em> are included as a service to the Spanish-speaking friends
        within the United States.
      </p>
      <p>
        They include major messages of the Universal House of Justice and of the National Spiritual Assembly, summary translations
        of selected English-language reports from <em>The American Bahá’í</em>, and notices of specific interest.
      </p>
      <p>
        If you have an article you think would be of specific interest to Spanish-speakers, please feel free to send it to{' '}
        <a href="mailto:tab@usbnc.org">tab@usbnc.org</a>
      </p>
    </div>
  );
}

export default StoriesForTheAmericanBahai;
